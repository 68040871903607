
import AddNewKpiModal from "@/components/modals/AddNewKpiModal.vue";
import kpi from "@/services/kpi";

export default {
  components: { AddNewKpiModal },
  data() {
    return {
      openModel: false,
      columns: [
        {
          name: "kpiType",
          align: "center",
          label: "KPI Type",
          field: "kpiType",
          sortable: true,
        },
        // {
        //   name: "kpiDeault",
        //   align: "left",
        //   label: "KPI Deault",
        //   field: "kpiDeault",
        //   sortable: true,
        // },
        // { name: "metrics", label: "Metrics", field: "metrics" },
        {
          name: "japanese",
          label: this.$store.state.user.translate.japanese,
          field: "japanese",
        },
        {
          name: "english",
          label: this.$store.state.user.translate.english,
          field: "english",
        },
        {
          name: "unitEN",
          label: this.$store.state.user.translate.unit + " " + "EN",
          field: "unitEN",
        },
        {
          name: "unitJP",
          label: this.$store.state.user.translate.unit + " " + "JP",
          field: "unitJP",
        },
        {
          name: "variationF",
          label: this.$store.state.user.translate.variation + " " + "F",
          field: "variationF",
        },
        {
          name: "variationU",
          label: this.$store.state.user.translate.variation + " " + "U",
          field: "variationU",
        },
        {
          name: "visibility",
          label: this.$store.state.user.translate.visibility,
          field: "visibility",
        },
        {
          name: "displayOrders",
          label: this.$store.state.user.translate.display_orders,
          field: "displayOrders",
        },
        // { name: "flowAndDashboard", label: "Flow and F Dashboard", field: "flowAndDashboard" },
        // { name: "xReport", label: "X Report", field: "xReport" },
        {
          name: "actions",
          label: this.$store.state.user.translate.actions,
          field: "actions",
        },
      ],
      loading: false,
      confirm: false,
      kpiData: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
      visible: false,
      selectedKpi: null,
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.kpiData.length / this.pagination.rowsPerPage);
    },
  },
  mounted() {
    this.visible = true;
    this.getKpiDefinations();
  },
  methods: {
    closeModel() {
      this.openModel = false;
    },
    getKpiDefinations() {
      this.visible = true;
      this.loading = true;
      kpi
        .getKpiDefinations()
        .then((response) => {
          this.kpiData = response.data.response.kpiDefinations;
          this.visible = false;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    openKpiModel() {
      (this.selectedKpi = null), (this.openModel = true);
    },
    async updateKpiDefination() {
      this.getKpiDefinations();
      this.openModel = false;
    },
    editKpiDialog(kpi) {
      this.selectedKpi = kpi;
      this.openModel = true;
    },
    deleteKpi(kpi) {
      this.selectedKpi = kpi;
      this.confirm = true;
    },
    deleteSelectedKpi() {
      kpi
        .deleteKpiDefination(this.selectedKpi._id)
        .then(() => {
          this.getKpiDefinations();
          this.$q.notify({
            message: this.$store.state.user.translate.deleted_successfully,
            color: "green",
          });
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
      this.confirm = false;
    },
  },
};
